import { memo } from "react";
import { SystemProps, Flex, Box, css } from "@storyofams/react-ui";
import { Image } from "@storyofams/storyblok-toolkit";

import { Heading, Button } from "../../../../../components";
import { getLinkProps } from "../../../../../lib";

import { Container } from "../../molecules/Container";
import { BrushStroke } from "./BrushStroke";
import { usePostHog } from "posthog-js/react";

type CallToActionProps = {
  first?: boolean;
  block?: boolean;
  footerColor?: string;
  content: any;
} & SystemProps;

export const CallToAction = memo(
  ({ content, first, block, footerColor, ...props }: CallToActionProps) => {
    const posthog = usePostHog();
    const { variant, text, button_label, button_url, image, color } =
      content?.call_to_action?.content || content || {};
    const body = (
      <Flex
        id={content?.anchor_id}
        position="relative"
        alignItems="center"
        justifyContent="center"
        flexDirection={
          variant === "img_left" ? "row-reverse" : ["column", "column", "row"]
        }
        width="100%"
        py={[5, 7]}
        {...props}
      >
        <BrushStroke
          right={variant === "img_left" && [-100, 60, "-20%"]}
          left={variant === "img_right" && [-100, 60, "-20%"]}
          color={footerColor || color?.color}
          transform={
            variant === "img_right"
              ? [
                  "scaleX(-1) translateY(-44%)",
                  "scaleX(-1) translateY(-44%) rotate(2deg)",
                  "scaleX(-1) translateY(-42%) rotate(4deg)",
                ]
              : [
                  "translateY(-44%)",
                  "translateY(-44%) rotate(2deg)",
                  "translateY(-42%) rotate(4deg)",
                ]
          }
        />
        <Flex
          maxWidth="384px"
          flexDirection="column"
          alignItems={["center", "center", "start"]}
          zIndex={50}
        >
          <Heading
            variant="h3"
            as={first ? "h1" : "h2"}
            fontWeight="medium"
            mb={5}
            textAlign={["center", "center", "left"]}
          >
            {text}
          </Heading>
          <Button
            variant="primary"
            // onClick={() => {
            //   if (button_label === "Join ons!")
            //     posthog.capture("Clicked Community Home Page");
            // }}
            href={getLinkProps(button_url)}
          >
            {button_label}
          </Button>
        </Flex>

        <Box
          ml={variant === "img_left" ? 0 : 14}
          mr={variant === "img_left" ? 14 : 0}
          width="36%"
          pt="36%"
          display={["none", "none", "block"]}
          position="relative"
          css={css({
            "> div": {
              position: "absolute !important",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
              borderRadius: "full",
              bg: "grey200",
            },
            img: {
              width: "100%",
              maxWidth: "100%",
              borderRadius: "full",
            },
          })}
        >
          <Image
            alt={image?.alt || ""}
            src={image?.filename}
            fluid={450}
            loading="lazy"
            fit="cover"
            showPlaceholder={false}
          />
        </Box>
      </Flex>
    );

    if (!block)
      return (
        <Container withOverflowHidden pt={0} pb={0}>
          {body}
        </Container>
      );

    return body;
  }
);
